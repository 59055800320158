import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import Amplify, { API } from 'aws-amplify';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import awsconfig from '../aws-exports';
import Layout from '../components/layout';
import SocialIcons from '../components/socialIcons';
import TextSection from '../components/textsection';
import TriviaCard from '../components/triviaCard';
import { pageImage } from './wikitrivia.module.css';

Amplify.configure(awsconfig);

const WikitriviaPage = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // get data from GitHub api
    if (loading === true) {
      API.get('magellanapi', '/trivia/random/10', {})
        .then((response) => {
          setQuestions(response);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error.response);
          setLoading(false);
        });
    }
  }, [loading]);
  return (
    <Layout pageTitle="Wikitrivia | EtherMagellan" pageDescription="Ether Magellan's Space for Wikitrivia Developments.">
      <Stack spacing={3}>
        <TextSection sectionTitle="WikiTrivia">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Typography variant="body1" paragraph sx={{ textIndent: '40px' }}>
                As a tribute to one of my favorite websites, Wikipedia, and my love for trivia, I wanted to create a fun resource that
                combined the two. Seems like an obvious combo right? As any person who is into natural language processing will tell you,
                Wikipedia is a treasure trove of (mostly) structured pages that is free to use under the creative commons license. However,
                the caveat is you have to figure out a way to process the 55+ million english language pages to do something with in the
                first place.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
              <StaticImage layout="constrained" className={pageImage} height={600} src="../images/wikipedia.png" />
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Typography variant="body1" paragraph sx={{ textIndent: '40px' }}>
                Wikipedia has a couple of API's that access its information, however I needed additional metrics to build in a concept of
                difficulty that required aggregate analytics of the Wikipedia English corpus as a whole. With API calls off the table the
                solution called for processing the monthly compressed dumps that Wikipedia publishes. With a 20+ GB compressed data dump for
                just the page text that I needed to match with page views data and group into categories from yet another dataset, figuring
                out a way to automate the data processing was mandatory. With the help of Python generators, multithreading, optimized text
                processing tools I created, temporary SQL tables, and some good old data science, Wikitrivia was born.
              </Typography>
              <Typography variant="body1" paragraph sx={{ textIndent: '40px' }}>
                The 5+ million questions (and counting!) generated through WikiTrivia currently lives in DynamoDB and I eventually plan to
                release the associated REST API as a DAAS for game creators to have a source of questions and answers to create word based
                games. For now have fun and play around with the some of the features here!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography variant="h4">Fetch Questions</Typography>
              <Button
                variant="contained"
                endIcon={<RefreshIcon />}
                onClick={() => {
                  setLoading(true);
                }}
              >
                Refresh
              </Button>
            </Grid>
            {questions.map((question) => (
              <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                {loading ? <TriviaCard skeleton={true} /> : <TriviaCard question={question} difficulty={0} />}
              </Grid>
            ))}
          </Grid>
        </TextSection>
        <SocialIcons />
      </Stack>
    </Layout>
  );
};

export default WikitriviaPage;
