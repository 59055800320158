import { Box, Button, Divider, Paper, Stack, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import * as React from 'react';
import { useState } from 'react';
import { paper } from './triviaCard.module.css';

const SimpleFade = ({ answer }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <FormControlLabel control={<Switch checked={checked} onChange={handleChange} />} label="Reveal Answer:" />
      <Box display="flex" justifyContent="center">
        <Fade in={checked}>
          <Typography align={'center'} variant={'h6'} component={'h6'}>
            {answer}
          </Typography>
        </Fade>
      </Box>
    </Box>
  );
};

const TriviaCard = ({ question, skeleton }) => {
  const cardMap = {
    0: { color: '#08d6a0', text: 'EASY' },
    1: { color: '#ffd166', text: 'MEDIUM' },
    2: { color: '#EF476F', text: 'HARD' },
    3: { color: '#C3BEF7', text: 'ESOTERIC' },
    yellow: '#ffd166',
    red: '#EF476F',
    blue: '#63D2FF',
    pink: '#FBCAEF',
    purple: '#C3BEF7'
  };
  return (
    <>
      {skeleton ? (
        <>
          <Skeleton variant="rectangular" width={'100%'} height={400} style={{ borderRadius: '24px' }} />
        </>
      ) : (
        <Paper className={paper}>
          <Box height="100%" display="flex" flexDirection="column">
            <Typography
              variant="h5"
              style={{ backgroundColor: cardMap[question.difficulty].color, textAlign: 'center', borderRadius: '24px 24px 0px 0px' }}
            >
              {cardMap[question.difficulty].text}
            </Typography>
            <Box p={2} display="flex" flexDirection="column" justifyContent="space-between" flex={1}>
              <Stack direction="column" justifyContent="space-between" style={{ height: '100%' }} spacing={1}>
                <Box>
                  <Box>
                    <span>
                      <b>HINT 0: </b>
                      {question.hint[0]}
                    </span>
                  </Box>
                  <Box>
                    <span>
                      <b>HINT 1: </b>
                      {question.hint[1]}
                    </span>
                  </Box>
                  <Box>
                    <span>
                      <b>HINT 2: </b>
                      {question.hint[2]}
                    </span>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column" alignItems="center" justifyContent={'center'}>
                  <Divider flexItem />
                  <Box display="flex" justifyContent={'center'} mt={2}>
                    <Stack direction="row" spacing={2}>
                      {question.word_length.map((wordLength) => (
                        <Box>
                          {'_ '.repeat(wordLength)}({wordLength})
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                  <SimpleFade answer={question.item_name} />
                  <Button variant="outlined" target="_blank" href={question.source_url} style={{ marginTop: '16px' }}>
                    View Source
                  </Button>
                  <Typography variant="caption">Information Sourced From Wikipedia</Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default TriviaCard;
